<template>
  <v-app style="background-color:#f8f9fb" >

    <v-divider></v-divider>
    <br/><br/>


  <v-container style="background-color:#eee"  
    id="financeiro"
    fluid
    tag="section">
    <v-row >

      <v-col
        cols="12">

        <base-material-card
        icon="mdi-link-variant"
        title="Não Conformidade"
        color="rgb(71 130 180)" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"> 

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row style="margin-top: 1rem;">

          <v-col
            class="d-flex" 
            cols="12"
            sm="12">

            <v-select 
                :items="vendedoresFormatados"
                item-text="displayName"
                item-value="id"
                v-model=controle.codigoPessoa
                filled
                label="Selecione um Coladorador"
            ></v-select>
          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="3">

            <v-text-field
                v-model=controle.venda
                filled
                label="ID da Venda"
            ></v-text-field>

          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="3">

            <v-select
                :items="mes"
                filled
                                required
                :rules="[v => !!v || 'Este campo é obrigatório']"
                v-model=controle.mes
                label="Mês"
            ></v-select>

          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="3">

            <v-select
                :items="ano"
                filled
                                required
                :rules="[v => !!v || 'Este campo é obrigatório']"
                v-model=controle.ano
                label="Ano"
            ></v-select>

          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="3">

            <v-select
                :items="motivos"
                filled
                v-model=controle.motivo
                label="Motivo"
            ></v-select>
          </v-col>
         </v-row>
        
         <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="adiciona_controle()">         
            <v-icon left>mdi-content-save</v-icon> Salvar
             </v-btn>
        </v-card-actions>


      </v-form> 
       

         <v-data-table style="margin-top: 1rem;"
              :headers="ranking_header"
              :items="lista_controle"
              :items-per-page="50" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      
              <br/> <br/> <br/>
  
              <template v-slot:top>
                <v-progress-linear
                  v-if="isLoading" 
                  indeterminate
                  color="rgb(71 130 180)"
                ></v-progress-linear>
              </template>

                        <template v-slot:item="row">

                          <tr :class="{'cor-row': row.index % 2 !== 0}">
                                
                                <td class="linha" style="width: 40%;font-size:14px;"> 
                                  {{ row.item.pessoa }} - CPF: {{ row.item.documento ? row.item.documento.slice(0, 6) + '***' : 'SEM CPF' }}
                                </td>

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{ row.item.venda || '' }}
                                </td>


                                <td class="linha" style="width: 30%;font-size:14px;"> 
                                  {{row.item.mes}} / {{row.item.ano}}
                                </td>

                                <td class="linha" style="width: 30%;font-size:14px;"> 
                                  {{row.item.motivo}}
                                </td>

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{row.item.data_insert}}
                                </td>

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  <v-btn @click="deleta(row.item.id)" fab small  color="error">
                                    <v-icon dark>
                                      mdi-delete
                                    </v-icon>
                                  </v-btn>                                
                                </td>
                                
                          </tr>

                      </template>
              </v-data-table>

        </base-material-card>  

      </v-col>

  </v-row>
</v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/FiltroGeral.vue"; // Certifique-se de que o caminho esteja correto
  import Comissao from '../../services/comissao' 
  import { ContentLoader } from 'vue-content-loader'


  export default {
    name: 'Obitos',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,

    },

    created() {     
    },

    mounted() {
     this.getVendedores();
     this.getListaControle();
    },

    computed: {
          vendedoresFormatados() {
              return this.lista_vendedores.map(vendedor => ({
                  ...vendedor,
                  displayName: `${vendedor.nome} - ${vendedor.cargo}`
              }));
          }
    },
   
    data () {
      return {
        
          lista_vendedores: [],
          ranking_header: [
              { text: 'Colaborador', value: 'name' },
              { text: 'Venda', value: 'local' },
              { text: 'Mês/Ano', value: 'mes' },
              { text: 'Motivo', value: 'motivo' },
              { text: 'Cadastro', value: 'cadastro' },

            ],
          lista_controle: [],
          produto: 'Todos',
          valid: false,
          controle:{
                      codigoPessoa: "",
                      motivo: "",
                      venda: "",
                      mes: "",
                      ano: "",

                  },
    
          isLoading: false,
          showFilterRegras: false,
          animationInterval: null, // Intervalo para controlar a animação
          empresa: '',
          selectedCompany: "Todas",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          categoria: [],
          showFilter: false,
          showProgress: false,
          showFilterDownload: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          tipos: ["Todos"],
          tipos_funerarios: [
                "Todos"
              ],
          motivos: ["Erros de procedimentos", "Não utilização do EPI", "Deixar de repassar informações para a continuidade do serviço", "Falha e/ ou falta de preenchimento da FCS", "Avarias, perdas ou quebra de bens e materiais", "Multas", "Comportamentos Inadequados", "Acumulo ou descarte inadequado dos lixos", , "Férias"],
          mes: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
          ano: ["2024", "2025", "2026", "2027"],
      }
    },
   
    methods: {
     
          adiciona_controle(){ 
            
            this.$refs.form.validate(); 
            
            if (this.valid) {
              Comissao.adiciona_conformidade(this.controle)
                .then(response => {
                 
                })
                .catch(e => {    
                })
                .finally(() => {
                  this.getListaControle();
                  this.clear();
                  this.valid = false;
                });
            }
          },

          deleta(id){
            Comissao.deletar_conformidade(id)
                .then(response => {
                })
                .catch(e => {    
                })
                .finally(() => {
                  this.getListaControle();
                });
          },

          
         

          getVendedores(){
              
              Comissao.lista_vendedores()
                .then(response => {
                  if(response.status === 200){
                    this.lista_vendedores = response.data;  
                  } 
                })
                .catch(e => {    
                })
                .finally(() => {
                
                });
            },

            getListaControle(){
              this.isLoading = true;

              Comissao.lista_conformidade()
                .then(response => {
                  if(response.status === 200){
                    this.lista_controle = response.data;  
                  } 
                })
                .catch(e => {    
                })
                .finally(() => {
                  this.isLoading = false;

                });
            },

           
            clear () {
              this.$refs.form.reset();
            },

    },
  }

</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
   flex: 0 1 auto !important; 
  margin: -12px;
}


/* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
.fill-width {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.v-menu__content{
  box-shadow:none !important;
  top: 4px !important;
}
/* Centralize horizontalmente */
.justify-center {
  justify-content: center;
}

.gray-row {
  background-color: rgb(71 130 180); /* ou qualquer outra cor cinza que você preferir */
}
.cor-row {
  background-color: rgb(231 244 255)   ;
  transition: none !important; /* Desativa a transição de cor */

}


.linha{

    font-size: 11px !important;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .no-hover-effect.v-data-table tbody tr:hover {
    color: #3055a4;
  }
.card-container {
  display: flex;
  justify-content: space-around; /* ou outro alinhamento que desejar */
}

</style>
